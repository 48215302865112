/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './styles/app.css';
// Import d'un fichier sass
import './styles/app.scss';
// import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';

// Feuilles de style  + ajout de bootstrap que l'on a chargé dans public/assets
// import './styles/bootstrap.min.css';
// Feuilles de style pour effet des pop up alert en JS 
import './styles/sweetalert2.css';

 // start the Stimulus application
// import './bootstrap';

// const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
// require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
// require('bootstrap/js/dist/carousel');
// require('bootstrap/js/dist/collapse');

// $(document).ready(function() {
//     $('[data-toggle="popover"]').popover();
// });

bsCustomFileInput.init();


